.vts-presenter.panelContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 355px;
    min-height: 50px;
    background-color: rgba(255,255,255,0.9);
    border-radius: 3px;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: opacity 1s ease;
    -moz-transition: opacity 1s ease;
    transition: opacity 1s ease;   
}

.vts-presenter.toolboxContainer {
    position: absolute;
    min-height: 50px;
    overflow: hidden;
    height: 0;
    opacity: 1;
    padding: 0 25px;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.vts-presenter.toolboxContainer article {
    position: relative;
    padding: 0;
    margin-top: 20px;
    top: 0;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.vts-presenter.toolboxContainer article.nonFirst {
    margin-top: -60px;
}

.vts-presenter.toolboxContainer section {
    font-family: 'Open Sans',Verdana,Arial,Tahoma,Calibri,Geneva,sans-serif;
    font-weight: 300;
    font-size: 13px;
    width: 305px;
    min-height: 200px;
    max-height: 800px;
    margin: 0;
    position: relative;
    vertical-align: top;
}

.vts-presenter.panelContainer div.swipeControl {
    position: absolute;
    top: 70px;
    height: 60px;
    width: 355px;
    text-align: center;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    transition: all 0.6s ease;
}

.vts-presenter.panelContainer div.swipeControl.top {
    top : 0;
}

.vts-presenter.toolboxContainer img {
    max-width: 295px;
    border-radius: 3px;
    border: double 5px rgb(216, 216, 216);
}

.vts-presenter .vts-presenter-btnUp {
    position: absolute;
    bottom: 15px;
    left: 50%;
    margin-left: -70px;
}

.vts-presenter .vts-presenter-btnUp div, .vts-presenter .vts-presenter-btnDw div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 18px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.vts-presenter .vts-presenter-btnUp div div, .vts-presenter .vts-presenter-btnDw div div {
    background-color: #000;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    position: absolute;
    top: -8px;
    left: 50%;
    margin-left: -10px;
    border-radius: 1px;
    box-shadow: 0 0 2px #000;
}

.vts-presenter .vts-presenter-btnUp div div {
    top: 6px;
}

.vts-presenter .vts-presenter-btnUp div:hover {
    top: -2px;
}

.vts-presenter .vts-presenter-btnDw div:hover {
    top: 2px;
}

.vts-presenter .vts-presenter-btnUp div div:hover {
    top: 6px;
}

.vts-presenter .vts-presenter-btnDw div div:hover {
    top: -8px;
}

.vts-presenter .vts-presenter-btnDw {
    position: absolute;
    top: 23px;
    left: 50%;
    margin-left: -70px;
}

.vts-presenter.toolboxContainer section.hidden {
    max-height: 0px;
    margin-bottom: 0;
    margin-top: 0;
}

.vts-presenter.toolboxContainer section h3 {
    font-weight: 300;
    font-size: 18px;
    margin: 0;
    padding: 15px 0 15px;
}

.vts-presenter.toolboxContainer section h4 {
    margin: 0;
    padding: 10px 0 10px 0;
    font-size: 15px;
}

.vts-presenter.toolboxContainer section a, .vts-presenter.toolboxContainer section p a {
    color: #0066ff;
    text-decoration: none;
    cursor: pointer;
}

.vts-presenter.toolboxContainer section a:hover, .vts-presenter.toolboxContainer section p a:hover {
    text-decoration: underline;
    cursor: pointer;
}

