.vts-presenter p {
    margin: 0;
    padding: 0;
}

.vts-presenter button {
    position: relative;
    width: 140px;
    height: 26px;
    cursor: pointer;
    outline: none;
    border: none;
    background: none;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 1;
}

.vts-presenter button.hidden {
    opacity: 0;
    cursor: default;
}

.vts-presenter.top-toolbox button {
    float: left;
}

.vts-presenter.widget .input-wrap {
    margin: 10px 10px 0px 10px;
    width: 180px;
    min-height: 16px;
}

.vts-presenter.widget .input-wrap label {
    font-size: 12px;
    height: 20px;
    width: 144px;
    margin-right: 10px;
    display: block;
    float: left;
    font-weight: bold;
}

.vts-presenter.input-wrap input[type='range'] {
    margin-right: 10px;
    width: 80px;
    display: block;
    float: left;
}

.vts-presenter.input-wrap span.value {
    font-size: 12px;
    width: 20px;
    display: block;
}

.vts-presenter.widget .input-wrap.nested {
    margin-left: 24px;
    width: 166px;
}

.vts-presenter.widget .input-wrap.nested label {
    width: 130px;
    font-weight: normal;
}

.vts-presenter.section {
    margin: 0px 0px 26px 0px;
}

.vts-presenter button, .vts-presenter button:hover {
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

